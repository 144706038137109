<template>
    <div>
        <div class="price">
            <span>￥{{arrs.amount}}</span>
        </div>
        <div class="card">
            <van-cell-group>
                <van-cell title="订单号" :value="arrs.orderId"/>
                <van-cell title="创建时间" :value="arrs.createTime"/>
                <van-cell title="备注" :value="arrs.remark"/>
                
            </van-cell-group>
        </div>
        <div class="button">
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'GroupOrder',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            localStorage.removeItem('groupOrderId');
            this.$router.push({name:'GroupPay'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "集团支付订单");
        if(!localStorage.groupOrderId){
            this.$toast.fail("未发现订单");
            this.$router.push('/group/pay')
        }else{
            let orderId = localStorage.groupOrderId
            this.$api.groupPayOrderInfo({orderId:orderId}).then(res => {
                if(res.data.code == 0)this.arrs = Object.assign({}, res.data.data);
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.price{
    span{
        color: #EB5E00;font-size: 60px;
    }
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
</style>
    
